@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
  width: 4px;
  height: 2px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(255,255,255,0.1);
  border-radius: 12px;
  box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.2);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  --tw-backdrop-blur: blur(16px);
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgba(255,255,255,0.2);
}

::-webkit-scrollbar-track {
  background-color: rgba(0,0,0,0.2);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
}


#app-container a.mapboxgl-ctrl-logo, .mapboxgl-ctrl-attrib {
  display: none !important;
}
